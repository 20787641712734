import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Products from "../components/product-list/products";
import CategoriesManFilter from "../components/CategoryManFilter";
import PaginationTwo from "../components/PaginationTwo";
// import ProductsStatic from "../components/products-static";

export default function ManPage({ data, pageContext }) {
  const list = [
    {
      id: "1v",
      media: "t_shirt_11.jpeg",
      name: "Футболка",
      slug: "man/futbolka-30",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
    {
      id: "1w",
      media: "t_shirt_12.jpeg",
      name: "Футболка",
      slug: "man/futbolka-31",
      category: [
        { id: "-0210e194-5108-5a37-ad32-3914d3185069", name: "Футболки" },
      ],
    },
  ];

  return (
    <Layout
      title="Мужская одежда"
      description="Трикотажная фабрика IMKON. Элегантность, удобство, качество"
    >
      <div className="bg-white">
        <div className="max-w-2xl mx-auto py-5 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
          <CategoriesManFilter activeCategory={pageContext.category} />
          {/* <ProductsStatic data={list} /> */}
          <Products data={data.mans} />
          <PaginationTwo
            pageSize={8}
            totalCount={data.mans.totalCount}
            currentPage={pageContext.currentPage || 1}
            skip={pageContext.skip}
            base="/man"
          />
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($skip: Int = 0, $pageSize: Int = 8) {
    mans: allSanityMan(limit: $pageSize, skip: $skip) {
      totalCount
      nodes {
        name
        category {
          name
          id
        }
        slug {
          current
        }
        id
        image {
          asset {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
  }
`;
